<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
        <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn medium color="primary" @click="btnsearch" class="mr-1">
            <v-icon left>mdi-reload</v-icon>LOAD DATA</v-btn
          >

<export-excel
            v-if="desserts.length > 0"
            :data="desserts"
            type="button"
          >
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-card-actions>
      <v-row>
      
          <!-- <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select> -->
        

        <!-- <v-col cols="12" md="3">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col> -->

        <v-col cols="12" md="2">
         
        </v-col>

        <v-col cols="12" md="2">
       

          
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <!-- @click="BtnApprove(index)" -->
        <templete v-slot:item.application_id="{ item }">
          <td class="tdalign">{{ item.sr_no }}</td>
          <td class="tdalign">{{ item.photo }}</td>
          <td class="tdalign">{{ item.firstName }}</td>
          <td class="tdalign">{{ item.lastName }}</td>
           <td class="tdalign">{{ item.gender }}</td>
          <td class="tdalign">{{ item.dob }}</td> 
            <td class="tdalign">{{ item.nationality }}</td>
          <td class="tdalign">{{ item.idProofType }}</td> 
          <td class="tdalign">{{ item.idProofNumber }}</td> 
          <td class="tdalign">{{ item.addLine1 }}</td> 
          <td class="tdalign">{{ item.addLine2 }}</td> 
          <td class="tdalign">{{ item.city }}</td> 
          <td class="tdalign">{{ item.state }}</td> 
          <td class="tdalign">{{ item.country }}</td> 
          <td class="tdalign">{{ item.zipCode }}</td> 
          <td class="tdalign">{{ item.mobile }}</td> 
          <td class="tdalign">{{ item.email }}</td> 
          <td class="tdalign">{{ item.mediaName }}</td> 
          <td class="tdalign">{{ item.mediaType_name }}</td> 
          <td class="tdalign">{{ item.organisationType_name }}</td> 
          <td class="tdalign">{{ item.status_named }}</td> 
<td class="tdalign">{{ item.reprintCount }}</td> 
          
          <td class="tdalign">{{ item.reason_name }}</td> 
          <td class="tdalign">{{ item.authName }}</td> 
          <td class="tdalign">{{ item.authRole }}</td> 
          <td class="tdalign">{{ item.authMobile }}</td> 
          <td class="tdalign">{{ item.authEmail }}</td> 
          <td class="tdalign">{{ item.role_name }}</td> 
          <td class="tdalign">{{ item.venue_name }}</td> 
          <td class="tdalign">{{ item.selectedMatchs }}</td> 
          <td class="tdalign">{{ item.fromHostNation }}</td>
          <td class="tdalign">{{ item.departPort }}</td>
          <td class="tdalign">{{ item.departDate }}</td>
          <td class="tdalign">{{ item.departTime }}</td>
          <td class="tdalign">{{ item.departFlight }}</td>
          <td class="tdalign">{{ item.arrivePort }}</td>
          <td class="tdalign">{{ item.arriveDate }}</td>
          <td class="tdalign">{{ item.arriveTime }}</td>
          <td class="tdalign">{{ item.arriveFlight }}</td>
          <td class="tdalign">{{ item.visaNo }}</td>
          <td class="tdalign">{{ item.visaValidTill }}</td>
          <td class="tdalign">{{ item.accAddLine1 }}</td>
          <td class="tdalign">{{ item.accAddLine2 }}</td>
          <td class="tdalign">{{ item.accCity }}</td>
          <td class="tdalign">{{ item.accState }}</td>
          <td class="tdalign">{{ item.accCountry }}</td>
          <td class="tdalign">{{ item.accZipCode }}</td>
          <td class="tdalign">{{ item.anyMedicalCondition }}</td>
          <td class="tdalign">{{ item.medicalCondition }}</td>
          <td class="tdalign">{{ item.isVaccinated }}</td>
          <td class="tdalign">{{ item.vaccineTaken }}</td>
          <td class="tdalign">{{ item.vaccineCountry }}</td>
          <td class="tdalign">{{ item.firstDose }}</td>
          <td class="tdalign">{{ item.secondDose }}</td>
          <td class="tdalign">{{ item.printDate }}</td>
          <td class="tdalign">{{ item.printflag }}</td>
          <td class="tdalign">{{ item.printBy }}</td>
          <td class="tdalign">{{ item.reprintCount }}</td>
          <td class="tdalign">{{ item.print_text }}</td>
          <td class="tdalign">{{ item.stand_name }}</td>
         
        </templete>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  data() {
    return {
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "8",

      SelstatusForUpade: "",

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],

      headers: [
        {
          text: "Sr_no",
          align: "center",
          value: "sr_no",
          class: "blue lighten-5",
        },
         {
          text: "App_id",
          align: "center",
          value: "app_id",
          class: "blue lighten-5",
        },
         {
          text: "Photo",
          align: "center",
          value: "photo",
          class: "blue lighten-5",
        },
         {
          text: "FirstName",
          align: "center",
          value: "firstName",
          class: "blue lighten-5",
        },
         {
          text: "LastName",
          align: "center",
          value: "lastName",
          class: "blue lighten-5",
        },
        {
          text: "Gender",
          align: "center",
          value: "gender",
          class: "blue lighten-5",
        },
        {
          text: "Dob",
          align: "center",
          value: "dob",
          class: "blue lighten-5",
        },
        {
          text: "Nationality",
          align: "center",
          value: "nationality",
          class: "blue lighten-5",
        },
        {
          text: "IdProofType",
          align: "center",
          value: "idProofType",
          class: "blue lighten-5",
        },
        {
          text: "IdProofNumber",
          align: "center",
          value: "idProofNumber",
          class: "blue lighten-5",
        },
         {
          text: "AddLine1",
          align: "center",
          value: "addLine1",
          class: "blue lighten-5",
        },
         {
          text: "AddLine2",
          align: "center",
          value: "addLine2",
          class: "blue lighten-5",
        },
         {
          text: "City",
          align: "center",
          value: "city",
          class: "blue lighten-5",
        },
         {
          text: "State",
          align: "center",
          value: "state",
          class: "blue lighten-5",
        },
         {
          text: "Country",
          align: "center",
          value: "country",
          class: "blue lighten-5",
        },
         {
          text: "ZipCode",
          align: "center",
          value: "zipCode",
          class: "blue lighten-5",
        },
         {
          text: "Mobile",
          align: "center",
          value: "mobile",
          class: "blue lighten-5",
        },
         {
          text: "Email",
          align: "center",
          value: "email",
          class: "blue lighten-5",
        },
         {
          text: "MediaName",
          align: "center",
          value: "mediaName",
          class: "blue lighten-5",
        },
         {
          text: "MediaType_name",
          align: "center",
          value: "mediaType_name",
          class: "blue lighten-5",
        },
        {
          text: "OrganisationType_name",
          align: "center",
          value: "organisationType_name",
          class: "blue lighten-5",
        },
        {
          text: "Status_name",
          align: "center",
          value: "status_named",
          class: "blue lighten-5",
        },

         {
          text: "ReprintCount",
          align: "center",
          value: "reprintCount",
          class: "blue lighten-5",
        },

        
        {
          text: "Reason_name",
          align: "center",
          value: "reason_name",
          class: "blue lighten-5",
        },
        {
          text: "AuthName",
          align: "center",
          value: "authName",
          class: "blue lighten-5",
        },
        {
          text: "AuthRole",
          align: "center",
          value: "authRole",
          class: "blue lighten-5",
        },
        {
          text: "AuthMobile",
          align: "center",
          value: "authMobile",
          class: "blue lighten-5",
        },
        {
          text: "AuthEmail",
          align: "center",
          value: "authEmail",
          class: "blue lighten-5",
        },
        {
          text: "Role_name",
          align: "center",
          value: "role_name",
          class: "blue lighten-5",
        },
        {
          text: "Venue_name",
          align: "center",
          value: "venue_name",
          class: "blue lighten-5",
        },
        {
          text: "SelectedMatchs",
          align: "center",
          value: "selectedMatchs",
          class: "blue lighten-5",
        },
        {
          text: "fromHostNation",
          align: "center",
          value: "fromHostNation",
          class: "blue lighten-5",
        },
         {
          text: "departPort",
          align: "center",
          value: "departPort",
          class: "blue lighten-5",
        },
         {
          text: "departDate",
          align: "center",
          value: "departDate",
          class: "blue lighten-5",
        },
         {
          text: "departTime",
          align: "center",
          value: "departTime",
          class: "blue lighten-5",
        },
         {
          text: "departFlight",
          align: "center",
          value: "departFlight",
          class: "blue lighten-5",
        },
         {
          text: "arrivePort",
          align: "center",
          value: "arrivePort",
          class: "blue lighten-5",
        },
         {
          text: "arriveDate",
          align: "center",
          value: "arriveDate",
          class: "blue lighten-5",
        },
         {
          text: "arriveTime",
          align: "center",
          value: "arriveTime",
          class: "blue lighten-5",
        },
         {
          text: "arriveFlight",
          align: "center",
          value: "arriveFlight",
          class: "blue lighten-5",
        },
         {
          text: "visaNo",
          align: "center",
          value: "visaNo",
          class: "blue lighten-5",
        },
         {
          text: "visaValidTill",
          align: "center",
          value: "visaValidTill",
          class: "blue lighten-5",
        },
         {
          text: "accAddLine1",
          align: "center",
          value: "accAddLine1",
          class: "blue lighten-5",
        },
         {
          text: "accAddLine2",
          align: "center",
          value: "accAddLine2",
          class: "blue lighten-5",
        },
         {
          text: "accCity",
          align: "center",
          value: "accCity",
          class: "blue lighten-5",
        },
         {
          text: "accState",
          align: "center",
          value: "accState",
          class: "blue lighten-5",
        },
         {
          text: "accCountry",
          align: "center",
          value: "accCountry",
          class: "blue lighten-5",
        },
         {
          text: "accZipCode",
          align: "center",
          value: "accZipCode",
          class: "blue lighten-5",
        },
         {
          text: "anyMedicalCondition",
          align: "center",
          value: "anyMedicalCondition",
          class: "blue lighten-5",
        },
         {
          text: "medicalCondition",
          align: "center",
          value: "medicalCondition",
          class: "blue lighten-5",
        },
         {
          text: "isVaccinated",
          align: "center",
          value: "isVaccinated",
          class: "blue lighten-5",
        },
         {
          text: "vaccineTaken",
          align: "center",
          value: "vaccineTaken",
          class: "blue lighten-5",
        },
        {
          text: "vaccineCountry",
          align: "center",
          value: "vaccineCountry",
          class: "blue lighten-5",
        },
        {
          text: "firstDose",
          align: "center",
          value: "firstDose",
          class: "blue lighten-5",
        },
        {
          text: "secondDose",
          align: "center",
          value: "secondDose",
          class: "blue lighten-5",
        },
        {
          text: "printDate",
          align: "center",
          value: "printDate",
          class: "blue lighten-5",
        },
        {
          text: "printflag",
          align: "center",
          value: "printflag",
          class: "blue lighten-5",
        },
        {
          text: "printBy",
          align: "center",
          value: "printBy",
          class: "blue lighten-5",
        },
        {
          text: "reprintCount",
          align: "center",
          value: "reprintCount",
          class: "blue lighten-5",
        },
        {
          text: "print_text",
          align: "center",
          value: "print_text",
          class: "blue lighten-5",
        },
        {
          text: "stand_name",
          align: "center",
          value: "stand_name",
          class: "blue lighten-5",
        },
       
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Media Complete Report");
   // this.$store.commit("setEventName", sessionStorage.display_event_name);
   // this.$store.commit("setVenueName", sessionStorage.display_venue_name);

   
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    // watch: {
    //   chkselected: function () {
    //     // this.sheet=true;
    //     if (this.chkselected.length > 0) {
    //       alert("greater");
    //     }
    //   },
    // },

    chkall: function () {
      alert("test");
      // this.bindReson(this.SelstatusForUpade);
    },

  

    btnsearch: function () {
      //console.log(this.Selstatus.);
      // this.sheet=false;
      this.Reload(this.$store.state.eventInfo.EventId);
    
    },

    

  

   

    Reload: async function (event_id) {
      this.loadingstatus = true;
      await this.$axios
        .get(
          "ReportMedia/CompleteAccrdReport/" + event_id + "/" + this.$store.state.eventInfo.VenueId
        )
        .then((res) => {
         // console.log(res.data.result);
          this.desserts = res.data.result;

          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
  text-align: center;
}
</style>>

